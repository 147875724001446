.profilePageHolder {
  display: flex;
  padding: 15px 0 0;
  flex-wrap: wrap;
  .navPart {
    width: 100%;
    margin-bottom: 30px;
  }
  .contentPart {
    width: 100%;
  }
  .mainSubscriptionPlanHolder {

  }
  .mainSubscriptionPlan {
    border-radius: 12px;
    background-color: $primaryColor;
    padding: 15px;
    color: #fff;
    margin-bottom: 45px;
    .userInfoWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .userInfoHolder {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .userName {
      font-size: 28px;
      margin: 0 0 5px;
      font-weight: bold;
    }
    .avaHolder {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      margin-right: 10px;
      background-color: white;
      color: #0576ED;
      align-items: center;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      font-size: 35px;
      font-weight: 500;
    }
    .planInfoHolder {
      .nameHolder {
        margin-bottom: 15px;
      }
      .subName {
        font-size: 14px;
      }
      .name {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .planInfoWrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 14px;
      .spInfoList {
        width: 60%;
        td + td {
          padding-left: 10px;
        }
      }
      .btnLike {
        border-radius: 10px;
        background-color: #fff;
        color: $primaryColor;
        padding: 6px 15px;
        display: block;
        &:hover {
          background: #b9dbff !important;
        }
      }
    }
  }
  .profileNavHolder {
  }
  .profileNav {
    list-style: none;
    .pnItem {
      border-bottom: 1px solid $borderColor;
      padding: 15px 10px;
      &:first-child {
        padding-top: 0;
      }
    }
    .pnItemContent {
      display: block;
      position: relative;
      padding-left: 42px;
      padding-right: 34px;
      &.active > .title,
      &.active > .description {
        color: $primaryColor;
      } 
      &:before, &:after {
        content: "";
        width: 32px;
        height: 32px;
        display: block;
        position: absolute;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:before {
        left: 0;
        background-image: url("#{$imgPathNew}/key-info.png");
        top: 5px;
      }
      &:after {
        right: 0;
        width: 24px;
        height: 24px;
        top: 50%;
        margin-top: -12px;
        background-image: url("#{$imgPathNew}/chevron-right.png");
      }
    }
    .title {
      display: block;
      font-size: 18px;
      color: #1F2123;
      font-weight: bold;
    }
    .description {
      display: block;
      color: #1F2123;
      font-size: 14px;
    }
  }
  .contentPartTitle {
    padding-bottom: 15px;
    border-bottom: 1px solid $borderColor;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
  }
  .text {
    font-size: 14px;
  }
  .cpSubTitle {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .subscriptionPlanList {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: -7.5px;
    .splItem {
      padding: 7.5px;
      width: 100%;
      &.active {
        .splItemContent {
          background: rgba(5,118,237,0.05);
          color: $primaryColor;
        }
      }
      &:not(.active) {
        .splItemContent {
          color: #fff;
        }
      }
    }
    .splItemContent {
      border: 2px solid $primaryColor;
      border-radius: 12px;
      background: $primaryColor;
      padding: 15px;
      font-size: 14px;
      height: 100%;
    }
    .splTitleHolder {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .spTitle {
      font-size: 18px;
      font-weight: bold;
    }
    .spSubTitle {

    }
    .pricePart {
      display: flex;
      align-items: flex-end;
    }
    .price {
      font-size: 18px;
      line-height: 20px;
      font-weight: bold;
      margin-right: 3px;
    }
    .spInfoList {
      margin-bottom: 15px;
      td + td {
        padding-left: 10px;
      }
    }
    .btnLike {
      border-radius: 10px;
      background-color: #fff;
      color: $primaryColor;
      padding: 5px 15px;
      display: inline-block;
    }
  }
  .paymentMethodList {
    display: flex;
    margin: -7.5px;
    flex-wrap: wrap;
    list-style: none;
    .pmlItem {
      padding: 7.5px;
      min-height: 140px;
      &.default {
        .pmlItemContent {
          display: flex;
          align-items: center;
          justify-content: center;
          border-color: transparent;
          .imgHolder {
            border-radius: 50%;
            background: rgba(5,118,237,0.05);
            padding: 15px;
            cursor: pointer;
          }
          img {
            display: block;
            width: 24px;
          }
        }
      }
    }
    .pmlItemContent {
      border: 2px solid $primaryColor;
      border-radius: 12px;
      background: rgba(5,118,237,0.05);
      width: 280px;
      height: 100%;
      padding: 15px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      color: $primaryColor;
      margin-bottom: 15px;
    }
    .cardInfo {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .imgHolder {
      img {
        width: 60px;
      }
    }
    .numberHolder {
      margin-left: 10px;
    }
  }
  .billingHistoryTableHolder {
    overflow-y: auto;
    .billingHistoryTable {
      width: 100%;
      font-size: 14px;
      text-align: left;
      th, td {

      }
      th {
        padding: 0 24px 24px;
      }
      td {
        padding: 6px 24px;
      }
      a {
        color: $primaryColor;
      }
      .date, .price {
        font-weight: bold;
      }
    }
  }
  .avaFormHolder {
    display: flex;
    align-items: center;
    .imgHolder {
      margin-right: 15px;
      img {
        width: 90px;
        height: 90px;
        display: block;
        border-radius: 50%;
      }
    }
  }
  .passRulesBlock {
    width: calc(50% - 30px);
    background: $primaryColor;
    color: #fff;
    padding: 30px 15px;
    border-radius: 12px;
    display: none;
    &.mobile {
      width: 100%;
      display: block;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 15px;
    }
    ul {
      padding-left: 20px;
    }
  }
  .profileFormHolder {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: $screen-sm) {
  .profilePageHolder {
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .profilePageHolder {
    .passRulesBlock {
      display: block;
      &.mobile {
        display: none;
      }
    }
    .formHolder {
      width: 50%;
    }
    .profileNavHolder {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .contentPartTitle {
      margin-left: 0;
      padding-left: 0;
    }
    .navPart {
      width: 100%;
      padding-right: 0;
    }
    .contentPart {
      width: 100%;
      border-left: none;
      padding-left: 0;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .profilePageHolder {
    .profileNavHolder {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .passRulesBlock {
      display: none;
      &.mobile {
        display: block;
      }
    }
    .formHolder {
      width: 100%;
    }
    .contentPartTitle {
      margin-left: 0;
      padding-left: 0;
    }
    .navPart {
      width: 100%;
      padding-right: 0;
    }
    .contentPart {
      width: 100%;
      border-left: none;
      padding-left: 0;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-xl) {
  .profilePageHolder {
    .passRulesBlock {
      display: block;
      &.mobile {
        display: none;
      }
    }
    .formHolder {
      width: 50%;
    }
    .profileNavHolder {
      margin-right: 0;
      margin-bottom: 30px;
    }
    .contentPartTitle {
      margin-left: 0;
      padding-left: 0;
    }
    .navPart {
      width: 100%;
      padding-right: 0;
    }
    .contentPart {
      width: 100%;
      border-left: none;
      padding-left: 0;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}

@media (min-width: $screen-xxl) {
  .profilePageHolder {
    .profileNavHolder {
      margin-right: -15px;
      margin-bottom: 0;
    }
    .contentPartTitle {
      margin-left: -15px;
      padding-left: 15px;
    }
    .navPart {
      width: 420px;
      padding-right: 15px;
    }
    .contentPart {
      width: calc(100% - 420px);
      border-left: 1px solid $borderColor;
      padding-left: 15px;
    }
    .subscriptionPlanList {
      .splItem {
        width: 50%;
      }
    }
  }
}