.campaignPageHolder {
  padding: 30px 0 0;
  .stepsList {
    list-style: none;
    .stepsListItem {
      position: relative;
    }
    .stepsListItem + .stepsListItem {
      padding-top: 60px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: #E4E6E7;
        width: 1px;
        height: 30px;
        left: 15px;
        top: 15px;
      }
    }
    .sliContent {
      background: #FBFBFB;
      border: 1px solid #E4E6E7;
      border-radius: 10px;
    }
    .sliTitleHolder {
      border-bottom: 1px solid #E4E6E7;
      background: #F5F5F5;
      padding: 15px;
      position: relative;
    }
    .sliTitle {
      font-size: 14px;
      font-weight: 600;
    }
    .sliSubMenu {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .sliBodyHolder {
      display: flex;
      padding: 15px;
      font-size: 14px;
      font-weight: 300;
      .qnt {
        flex-shrink: 0;
        padding-left: 15px;
        color: #AFB1B3;
      }
    }
  }
  .campSettingsHolder {
    margin-top: 30px;
  }
}

.campaignsPageHolder {
  padding: 30px 0;
}

.tabsWithStatusHolder {
  position: relative;
  .campaignStatus {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    font-weight: bold;
    padding-left: 11px;

    .campaignStatusText {
      &:before {
        content: '';
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -3px;
      }
    }
    &.draft {
      .campaignStatusText {
        &:before {
          background-color: #E0C852;
        }
      }
    }
    &.active {
      .campaignStatusText {
        &:before {
          background-color: #72C07F;
        }
      }
    }
  }
}

@media (min-width: $screen-xxl) {
  .campaignPageHolder {
    display: flex;
    justify-content: space-between;
    .campSettingsHolder {
      margin-top: 0;
      width: 40%;
    }
    .campStepsHolder {
      width: calc(60% - 30px);
    }
  }
}