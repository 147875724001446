.Toastify__toast-container {
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);

  .Toastify__toast {
    border-radius: 5px;

    .Toastify__toast-body {
      padding: 10px;
      font-size: 0.9em;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    .Toastify__close-button {
      outline: none;
    }
  }
}
