.searchHolder {
  .resultHolder {
    margin-top: 15px;
  }
}

.chipLike {
  display: inline-flex;
  color: #fff;
  background-color: $gray400;
  font-weight: 600;
  font-size: 11px;
  padding: 3px 10px 3px 10px;
  line-height: 15px;
  border-radius: 5px;
  vertical-align: middle;
  align-items: center;
  width: 85px;
  justify-content: center;
  &.success, &.active {
    background-color: $successColor;
  }
  &.warning, &.inactive, &.failed {
    background-color: $warningColor;
  }
  &.primary {
    background-color: $primaryColor;
  }
  &.blackList {
    background-color: $blacklistColor;
  }
  &.flagged, &.scheduled {
    background-color: $flaggedColor;
  }
  &.pending, &.draft {
    background-color: $pendingColor;
  }
  &.paused {
    background-color: $pausedColor;
  }
  &.completed {
    background-color: $primaryColor;
  }

}

.fileDropHolder {
  border: 2px dashed $primaryColor;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  .icoHolder {
    img {
      display: block;
      margin: auto;
    }
  }
}

.fontSize14 {
  font-size: 14px;
}

.MuiTextField-root {
  &.freqAdornment {
    .MuiOutlinedInput-adornedEnd {
      padding-right: 7px;
    }
    .MuiInputAdornment-root {
      .MuiInput-root {
        &:before,
        &:after {
          display: none;
        }
      }
      .MuiSelect-select:focus {
        background-color: transparent;
      }
    }
  }
}

.fileProgressHolder {
  .fileProgress {
    list-style: none;
  }
  .fileProgressItem {
    display: flex;
    align-items: center;
    .icoHolder {
      flex-shrink: 0;
      margin-right: 15px;
      img {
        display: block;
      }
    }
  }
  .progress {
    width: calc(100% - 45px);
  }
  .progressStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.editTextOnClick {
  display: flex;
  align-items: center;
  .editBtn {
    font-size: 15px;
    padding: 10px;
  }
  .saveBtn {
    font-size: 15px;
    padding: 10px;
  }
  .captionPart {
    width: 100%;
    .caption {
      padding: 6px 0 7px;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      cursor: pointer;
    }
  }
  .btnPart {
    width: 38px;
    margin-left: 10px;
  }
}

@media (min-width: $screen-lg) {
  .searchHolder {
    display: flex;
    align-items: center;
    &.hasFilter {
      justify-content: space-between;
    }
    .resultHolder {
      margin-left: 15px;
      margin-top: 0;
    }
  }
}
