.leadPageWrapper {
  padding: 0 15px;

  .timeLineHolder {
  }
  .timeLine {
    margin-top: 30px;
  }
}

.leadDetailsHolder {
}
.leadDetails {
  padding: 30px;
  background: $gray100;
  border-radius: 10px;
  border: 1px solid $borderColor;
}

.leadDetailsHeading {
  position: relative;
  .moreIcon {
    position: absolute;
    top: -8px;
    right: -20px;
  }
}

.timeLineHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leadDetailsTableLike {
  &.campSettingsTable {
    .tr {
      .td {
        &:first-child {
          white-space: nowrap;
        }
      }
    }
  }

  .tableLike {
    display: table;
    width: 100%;
    font-size: $fontSizeMute;
  }
  .tr {
    display: table-row;
    .td {
      &:first-child {
        color: $gray500;
        font-weight: 600;
        padding-left: 0;
      }
      &:last-child {
        font-weight: 300;
        padding-right: 0;
        width: 100%;
      }
    }
    &:last-child {
      .td {
        padding-bottom: 0;
      }
    }
  }
  .td {
    display: table-cell;
    padding: 15px;
  }
  .tr + .tr {
    .td {
      border-top: 1px solid $borderColor;
    }
  }
  .MuiChip-root {
    &.slChip {
      margin-right: 5px;
      margin-bottom: 5px;
      background: #EEF5FC;
      border: 1px solid #DBE8F7;
      color: #0576ED;
      border-radius: 5px;
      &:hover {
        background: darken(#EEF5FC, 2%);
      }
    }
  }
}

.MuiChip-root {
  &.tagLikeChip {
    margin-right: 5px;
    margin-bottom: 5px;
    background: #EEF5FC;
    border: 1px solid #DBE8F7;
    color: #0576ED;
    border-radius: 5px;
    &:hover {
      background: darken(#EEF5FC, 2%);
    }
    &.success, &.active {
      background-color: $successColor;
      border-color: $successColor;
      color: #fff;
      &:hover {
        background: darken($successColor, 2%);
      }
    }
    &.warning, &.inactive, &.failed {
      background-color: $warningColor;
      border-color: $warningColor;
      color: #fff;
      &:hover {
        background: darken($warningColor, 2%);
      }
    }
    &.blackList {
      background-color: #000;
      border-color: #000;
      color: #fff;
      &:hover {
        background: darken(#000, 2%);
      }
    }
    &.pending, &.draft {
      background-color: $pendingColor;
      border-color: $pendingColor;
      color: #fff;
      &:hover {
        background: darken($pendingColor, 2%);
      }
    }
    &.flagged, &.scheduled {
      background-color: $flaggedColor;
      border-color: $flaggedColor;
      color: #fff;
      &:hover {
        background: darken($flaggedColor, 2%);
      }
    }
    &.paused {
      background-color: $pausedColor;
      border-color: $pausedColor;
      color: #fff;
      &:hover {
        background: darken($pausedColor, 2%);
      }
    }
    &.completed {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: #fff;
      &:hover {
        background: darken($primaryColor, 2%);
      }
    }
  }
}

@media (min-width: $screen-xxl) {
  .leadPageWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    //flex-wrap: wrap;
    .leadDetailsHolder {
      //width: calc(33.333% - 7.5px);
      width: calc(41.666% - 7.5px);
      //min-width: 530px;
    }
    .leadDetails {
      padding: 30px;
      background: $gray100;
      border-radius: 10px;
      border: 1px solid $borderColor;
    }
    .timeLineHolder {
      //width: calc(66.666% - 7.5px);
      width: calc(58.333% - 37.5px);
      //min-width: 680px;

    }
    .timeLine {
      padding: 30px 0;
      margin-top: 0;
    }
  }
}
