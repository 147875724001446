@import "~@fortawesome/fontawesome-free/css/all.css";
.chatBtnHolder {
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 30px;
  .chatBtn {
    padding-left: 15px;
    padding-right: 15px;
    .caption {
      display: none;
      margin-right: 10px;
    }
    .icon {
      display: block;
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .fab {
      font-size: 20px;
    }
  }
}

@media (min-width: $screen-sm) {

}


@media (min-width: $screen-md) {
  .chatBtnHolder {
    .chatBtn {
      .caption {
        display: block;
      }
    }
  }
}


@media (min-width: $screen-lg) {

}


@media (min-width: $screen-xl) {

}
