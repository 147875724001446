.tableHolder {
  position: relative;
  table {
    .col-sticky {
      background-color: #fff;
      position: sticky;
      right: 0;
    }
    .xNum {
      font-size: 11px;
      color: gray;
    }
  }
  overflow-y: auto;
  &.rowClickable {
    td {
      cursor: pointer;
    }
    tr {
      &:hover {
        td {
          background-color: #f5f5f5;
        }
      }
    }
  }
  &.stlg {
    th {
      padding-top: 30px;
    }
  }
  &.noBorderTop {
    th {
      border-top: none;
    }
  }
  .checkboxHolder {
    //display: none;
    //position: absolute;
    //left: -13px;
    //width: 10px;
    //height: 10px;
    //background-color: #ff0000;
    //margin-top: 5px;
    padding: 0;
  }
  th.checkboxHolder {
    padding-top: 5px;
  }
  tr {
    position: relative;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  th {
    border-top: 1px solid $borderColor;
    padding: 15px 5px 10px 5px;
    white-space: nowrap;
    &.hasArrow {
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      &:after {
        content: "";
        display: inline-block;
        background: url("#{$imgPath}/thin_long_02_down.svg") no-repeat;
        background-size: contain;
        background-position: center;
        height: 11px;
        width: 8px;
        margin-top: -3px;
        vertical-align: middle;
        margin-left: 3px;
      }

      &.up {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  th, td {
    text-align: left;
    vertical-align: middle;
  }
  td {
    font-weight: 300;
    padding: 5px;
  }
  //tr {
  //  th, td {
  //    &:first-child {
  //      padding-left: 0;
  //    }
  //    &:last-child {
  //      padding-right: 0;
  //    }
  //  }
  //}
  &.hasCheckboxes {
    tr {
      th, td {
        &:nth-child(2) {
          padding-left: 0;
        }
        //&:last-child {
        //  padding-right: 0;
        //}
      }
    }
  }
}

.formatTableHolder {
  .formatTable {
    width: 100%;
    th, td {
      padding: 8px 0;
    }
    th {
      font-weight: 600;
      font-size: 14px;
      padding-top: 0;
      text-align: left;
    }
    td {

    }
    tr {
      td {
        &:first-child {
          width: calc(100% - 150px);
          font-weight: 300;
          font-size: 14px;
        }
        &:last-child {
          width: 150px;
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
    .MuiFormControl-root {
      max-width: 150px;
    }
  }
}

.tableProgressBarItem {
  display: flex;
  align-items: center;
  .qnt {
    flex-shrink: 0;
    font-size: 10px;
    line-height: 12px;
    margin-left: 5px;
    width: 30px;
    text-align: right;
  }
  .pbItemHolder {
    width: 100%;
    position: relative;
    height: 5px;
    border-radius: 2px;
    background: #fafafa;
    .pbItem {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $successColor;
      border-radius: 2px;
    }
  }
  &.secondary {
    .pbItemHolder {
      .pbItem {
        background: $primaryColor;
      }
    }
  }

}

.timeLineTable {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  td {
    padding: 15px;
    font-size: 14px;
    font-weight: 300;
    &.hasBorder {
      border-bottom: 1px solid $borderColor;
      td {
        border-top: 1px solid $borderColor;
      }
    }
  }
  tr {
    td {
      &:first-child {
        width: 170px;
      }
    }
  }
  .MuiCollapse-container {
    background-color: $gray100;
  }
}
