.pageTabs {
  &.MuiTabs-root {
    min-height: unset;
    border-bottom: 1px solid #E4E6E7;
    &.biggerPad {
      .MuiTab-root {
        padding-bottom: 20px;
      }
    }
    .MuiTab-root {
      padding: 0 0 15px;
      min-height: unset;
      text-transform: none;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      min-width: unset;
      &.Mui-selected {
        color: $primaryColor;
      }
    }
    .MuiTab-root + .MuiTab-root {
      margin-left: 30px;
    }
  }
}