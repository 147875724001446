.modal {
  .modalBody {
    max-width: 496px;
    width: 100%;
    padding: 30px;
  }
}
.modalField {
  background: #fafafa;

  .MuiOutlinedInput-root {
    border-radius: 10px;
    font-family: "Ubuntu", sans-serif;
  }

  .MuiFormLabel-root {
    font-family: "Ubuntu", sans-serif;
  }
}

  
  

.centred {
  text-align: center;
}