.btnHolder {
  &.hasPadTop {
    padding-top: 30px;
  }
  &.hasPadBottom {
    padding-bottom: 30px;
  }
  &.twoBtn {
    .part {
    }
    .part + .part {
      margin-top: 15px;
    }
  }
}

.primaryColor {
  background: $primaryColor !important;
  &:hover {
    background: #046ad4 !important;
  }
}

.secondaryColor {
  color: #046ad4 !important;
}

.primaryColorOutlined {
  color: $primaryColor !important;
  border-color: $primaryColor !important;
  &:hover {
    color: #046ad4 !important;
    border-color: #046ad4 !important;
  }
}

.btn {
  display: inline-flex;
  border: 2px solid $primaryColor;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
  color: $primaryColor;
  line-height: 22px;
  padding: 8px 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background: $primaryColor;
    color: #fff;
  }
  &.contained {
    background: $primaryColor;
    color: #fff;
    &:hover {
      background: darken($primaryColor, 5%);
    }
  }
}
.requestModalBtn {
  border-radius: 10px !important;
  padding: 7px 16px !important;
  cursor: pointer;
  transition: 0.2s;
  font-family: "Ubuntu", sans-serif !important;
  .MuiButton-label {
    text-transform: capitalize;
  }
}

.dropDownLink {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  font-size: $fontSizeMute;
  cursor: pointer;
}

@media (min-width: $screen-sm) {
  .btnHolder {
    &.twoBtn {
      display: flex;
      .part {
        width: calc(50% - 15px);
      }
      .part + .part {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.btn-billing {
  border-radius: 10px !important;
  background-color: #fff !important;
  color: $primaryColor !important;
  display: block !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  &:hover {
    background: #b9dbff !important;
  }
  &:disabled {
    background: #e4e1e1 !important;
  }
}
