.authLayout {
  background: linear-gradient(135deg, #EEF7FF 0%, #DBEDFC 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.authLayout:after {
  content: '';
  display: block;
  background-image: url("#{$imgPath}/wave.png");
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 25%;
  z-index: 2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.authContent {
  display: flex;
  color: #5c798a;
  align-items: center;
  padding: 30px 15px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  .imgPart {
    flex-shrink: 0;
    width: 479px;
    img {
      display: block;
      max-width: 100%;
    }
  }
  .formPart {
    flex-shrink: 0;
    max-width: 378px;
    width: 100%;
    z-index: 100;
    //margin-left: 132px;
  }
}

.authFormNav {
  list-style: none;
  display: flex;
  align-items: center;
  .authFormNavItem {
    a {
      color: #59b5ec;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;
      display: block;
      font-family: $fontFamilySecondary;
      font-size: $fontSizeH2;
      &.active {
        color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
  .authFormNavItem + .authFormNavItem {
    margin-left: 30px;
  }
}
.logoHolderAuth {
  flex-shrink: 0;
  width: $widthMainMenu;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-bottom: 20px;
  img {
    display: block;
    max-width: 100%;
  }
}

.loginField {
  background-color: #e2f1fd;

  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #59b5ec;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #59b5ec;
  }

  .MuiOutlinedInput-input {
    padding: 12px 15px;
    background: #e2f1fd;
  }
}